import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/User/Login.vue'),
  },
  //登录
  {
    path: '/Login',
    component: () => import('@/views/User/Login.vue'),
  },
  //注册
  {
    path: '/Register',
    component: () => import('@/views/User/Register.vue'),
  },
  //忘记密码
  {
    path: '/Forget',
    component: () => import('@/views/User/Forget.vue'),
  },
  //忘记密码
  {
    path: '/Messages',
    component: () => import('@/views/User/Messages.vue'),
  },
  //重置密码
  {
    path: '/Reset',
    component: () => import('@/views/User/Reset.vue'),
  },

  //展商-首页
  {
    path: '/Exhibition',
    component: () => import('@/views/Exhibition/index.vue'),
  },
  //展商-基础信息
  {
    path: '/Exhibition/Base',
    component: () => import('@/views/Exhibition/Base/index.vue'),
  },
  //展商-展品列表
  {
    path: '/Exhibition/Exhibits',
    component: () => import('@/views/Exhibition/Exhibits/index.vue'),
  },
  //展商-添加展品
  {
    path: '/Exhibition/Exhibits/Add',
    component: () => import('@/views/Exhibition/Exhibits/add.vue'),
  },
  //展商-项目列表
  {
    path: '/Exhibition/Project',
    component: () => import('@/views/Exhibition/Project/index.vue'),
  },
  //展商-添加项目
  {
    path: '/Exhibition/Project/Add',
    component: () => import('@/views/Exhibition/Project/add.vue'),
  },
  //展商-证件申请
  {
    path: '/Exhibition/Document',
    component: () => import('@/views/Exhibition/Document/index.vue'),
  },
  //展商-添加证件
  {
    path: '/Exhibition/Document/Add',
    component: () => import('@/views/Exhibition/Document/add.vue'),
  },
  //展商-展商新闻
  {
    path: '/Exhibits/News',
    component: () => import('@/views/Exhibition/News/index.vue'),
  },
  // 添加展商新闻
  {
    path: '/Exhibits/News/Add',
    component: () => import('@/views/Exhibition/News/add.vue'),
  },

  //展商-展商活动
  {
    path: '/Exhibits/Activity',
    component: () => import('@/views/Exhibition/Activity/index.vue'),
  },
  // 添加展商活动
  {
    path: '/Exhibits/Activity/Add',
    component: () => import('@/views/Exhibition/Activity/add.vue'),
  },
  // 观众邀请-海报
  {
    path: '/Exhibits/Invite',
    component: () => import('@/views/Exhibition/Invite/index.vue'),
  },
  // 观众邀请添加
  {
    path: '/Exhibits/Invite/Add',
    component: () => import('@/views/Exhibition/Invite/add.vue'),
  },
  // 下载中心
  {
    path: '/Exhibits/Download',
    component: () => import('@/views/Exhibition/Download/index.vue'),
  },

  // 展商中心-展商列表
  {
    path: '/Center/Exhibitor',
    component: () => import('@/views/Center/Exhibitor/index.vue'),
  },

  // 展商中心-展商列表-展商详情
  {
    path: '/Center/Exhibitor/Detail',
    component: () => import('@/views/Center/Exhibitor/details.vue'),
  },

  // 展商中心-项目列表
  {
    path: '/Center/Project',
    component: () => import('@/views/Center/Project/index.vue'),
  },
  // 展商中心-项目列表-项目详情
  {
    path: '/Center/Project/Detail',
    component: () => import('@/views/Center/Project/details.vue'),
  },
  // 展商中心-产品列表
  {
    path: '/Center/Product',
    component: () => import('@/views/Center/Product/index.vue'),
  },

  // 展商中心-产品列表-详情
  {
    path: '/Center/Product/Detail',
    component: () => import('@/views/Center/Product/details.vue'),
  },

  // 展商中心-分类筛选列表
  {
    path: '/Center/Classify',
    component: () => import('@/views/Center/Classify/index.vue'),
  },

  // 展商中心-新闻列表
  {
    path: '/Center/News',
    component: () => import('@/views/Center/News/index.vue'),
  },
  // 展商中心-新闻详情
  {
    path: '/Center/News/Detail',
    component: () => import('@/views/Center/News/details.vue'),
  },
  // 展商中心-活动列表
  {
    path: '/Center/Activity',
    component: () => import('@/views/Center/Activity/index.vue'),
  },
  // 展商中心-活动详情
  {
    path: '/Center/Activity/Detail',
    component: () => import('@/views/Center/Activity/details.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash', //history
  base: process.env.BASE_URL,
  routes,
});

export default router;
